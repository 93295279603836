@import "./fonts/ABCDiatypePlusVariable.css";

a {
  text-decoration: none;
}

.jespr-main-content {
  padding: 2em;
}

.jespr-hoverable:hover {
  background-color: DarkSlateGray !important;
}
